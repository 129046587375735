import { IColumn, IconButton } from '@fluentui/react';
import { ISubscription } from '../../../constants/interfaces';

export const SubscriptionsColumns = (
  items: ISubscription[],
  setItems?: (items: ISubscription[]) => void,
  includeRemoveColumn: boolean = false,
  readOnly: boolean = false
): IColumn[] => {
  const handleRemoveItem = (skuType: string) => {
    const updatedList = items.filter((item) => item.SkuType !== skuType);
    if (setItems) {
      setItems(updatedList);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'SkuType',
      name: 'Sku Type',
      fieldName: 'SkuType',
      minWidth: 175,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: ISubscription) => <span>{item.SkuType}</span>,
    },
    {
      key: 'LicenseCount',
      name: 'License Count',
      fieldName: 'LicenseCount',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: ISubscription) => item.LicenseCount ?? 'default',
    },
  ];

  if (includeRemoveColumn) {
    columns.push({
      key: 'Remove',
      name: 'Remove',
      fieldName: 'Remove',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: ISubscription) => (
        <IconButton
          iconProps={{
            iconName: 'Delete',
            styles: { root: { color: readOnly ? 'gray' : 'red' } },
          }}
          onClick={() => handleRemoveItem(item.SkuType)}
          disabled={readOnly}
          styles={{
            root: readOnly ? { backgroundColor: 'white' } : {},
            rootDisabled: { backgroundColor: 'white' },
          }}
        />
      ),
    });
  }

  return columns;
};

export default SubscriptionsColumns;
