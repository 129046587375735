import { IColumn, IconButton } from '@fluentui/react';
import { HydrationTarget } from '../../../constants/interfaces';

export const HydrationTargetColumns = (
  hydrationTargets: HydrationTarget[],
  setHydrationTargets?: (hydrationTarget: HydrationTarget[]) => void,
  includeRemoveColumn: boolean = true,
  readOnly: boolean = false
): IColumn[] => {
  const handleRemoveHydrationTarget = (target: string) => {
    const updatedList = hydrationTargets.filter((t) => t.ProfileName !== target);
    if (setHydrationTargets) {
      setHydrationTargets(updatedList);
    }
  };

  const columns: IColumn[] = [
    {
      key: 'Profile',
      name: 'Profile',
      fieldName: 'Profile',
      minWidth: 175,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: HydrationTarget) => <span>{item.ProfileName}</span>,
    },
    {
      key: 'Count',
      name: 'Count',
      fieldName: 'Count',
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: HydrationTarget) => item.Count,
    },
  ];

  if (includeRemoveColumn) {
    columns.push({
      key: 'Remove',
      name: 'Remove',
      fieldName: 'Remove',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      onRender: (item: HydrationTarget) => (
        <IconButton
          iconProps={{
            iconName: 'Delete',
            styles: { root: { color: readOnly ? 'gray' : 'red' } },
          }}
          onClick={() => handleRemoveHydrationTarget(item.ProfileName)}
          disabled={readOnly}
          styles={{
            root: readOnly ? { backgroundColor: 'white' } : {},
            rootDisabled: { backgroundColor: 'white' },
          }}
        />
      ),
    });
  }

  return columns;
};

export default HydrationTargetColumns;
